<script>
import axios from 'axios';

export default {
  name: "Notes",
  data() {
    return {
      showForm: false,
      notes: [],
      user: {
        Prenom: '',
        Nom: '',
        Photo: '',
        Date: '' 
      },
      form: {
        priority: 'normal',
        note: ''
      }
    };
  },
  methods: {
    toggleForm() {
      if (!this.showForm) {
        this.showForm = true;
        this.fetchUserInfo(); 
      }
    },
    hideForm() {
      this.showForm = false;
    },
    async fetchNotes() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://private-api.hbc-group.fr/note/list', { token });
        this.notes = response.data.notes;
      } catch (error) {
        console.error("Erreur lors de la récupération des notes:", error);
      }
    },
    async fetchUserInfo() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://private-api.hbc-group.fr/note/infouser', { token });
        this.user = response.data;
      } catch (error) {
        console.error("Erreur lors de la récupération des informations utilisateur:", error);
      }
    },
    async submitForm() {
      try {
        const token = localStorage.getItem('token');
        const { priority, note } = this.form;
        const response = await axios.post('https://private-api.hbc-group.fr/note/create', {
          token,
          status: priority,
          date: this.user.Date,
          description: note
        });
        console.log('Note created successfully:', response.data);
        this.fetchNotes(); 
        this.clearForm(); 
        this.hideForm(); 
      } catch (error) {
        console.error("Erreur lors de la création de la note:", error);
      }
    },
    clearForm() {
      this.form = {
        priority: 'normal',
        note: ''
      };
    },
    statusClass(status) {
      return {
        'text-green-300': status === 'Normal',
        'text-orange-300': status === 'Moyen',
        'text-red-300': status === 'Urgent'
      };
    },
    formatDescription(description) {
      // Utilise une expression régulière pour détecter les mentions
      return description.replace(/@(\w+)/g, '<span class="mention">@$1</span>');

    }
  },
  mounted() {
    this.fetchNotes();
  }
}
</script>


<template>
  <section class="flex w-full p-6 gap-6 flex-wrap">
    <div
      @click="toggleForm"
      class="note flex flex-col justify-center items-center text-white rounded p-4 gap-4 hover:bg-neutral-700 cursor-pointer border-white border-2 border-dotted"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="54"
        height="54"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-circle-plus"
      >
        <circle cx="12" cy="12" r="10"/>
        <path d="M8 12h8"/>
        <path d="M12 8v8"/>
      </svg>
    </div>

    <div
      v-if="showForm"
      class="note flex flex-col text-white bg-neutral-900 rounded p-4 gap-4"
    >
      <div class="state flex items-center justify-between">
        <small>{{ user.Date }}</small>
        <button @click="hideForm" class="hover:text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-trash-2"
          >
            <path d="M3 6h18"/>
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
            <line x1="10" x2="10" y1="11" y2="17"/>
            <line x1="14" x2="14" y1="11" y2="17"/>
          </svg>
        </button>
      </div>
      <div class="title flex items-center gap-2">
        <img :src="user.Photo" class="w-12 flex"/>
        <h1>{{ user.Prenom }} {{ user.Nom }}</h1>
      </div>
      <select v-model="form.priority" name="priority" id="priority" class="custom-select w-full">
        <option value="Normal">Normal</option>
        <option value="Moyen">Moyen</option>
        <option value="Urgent">Urgent</option>
      </select>
      <textarea v-model="form.note" name="note" id="note" placeholder="Votre message" class="border rounded"></textarea>
      <button @click="submitForm" class="bg-blue-300 px-4 py-2 flex justify-center items-center rounded hover:bg-blue-400">Enregistrer</button>
    </div>

  
    <div
      v-for="note in notes"
      :key="note.id"
      class="note flex flex-col text-white bg-neutral-900 rounded p-4 gap-4 hover:bg-neutral-700"
    >
      <div class="state flex items-center justify-between">
        <small>{{ new Date(note.date).toLocaleDateString() }} {{ new Date(note.date).toLocaleTimeString() }}</small>
        <button class="hover:text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-pencil"
          >
            <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"/>
            <path d="m15 5 4 4"/>
          </svg>
        </button>
      </div>
      <div class="title flex items-center gap-2">
        <img :src="note.Photo" class="w-12 flex"/>
        <h1>{{ note.Prenom }} {{ note.Nom }}</h1>
      </div>
      <span :class="statusClass(note.status)" class="flex px-2 py-1 rounded w-auto gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-circle"
        >
          <circle cx="12" cy="12" r="10"/>
        </svg>
        {{ note.status }}
      </span>
      <div class="message flex w-full overflow-y-scroll">
        <span class="cursor-default" v-html="formatDescription(note.description)"></span>

      </div>
    </div>


  </section>
</template>

<style scoped>
 .mention {
    color: orange; /* Couleur pour les mentions */
  }
  .note{
    max-width: 289px;
    min-width: 289px;
    max-height: 460px;
    min-height: 460px;
  }

  .message{
    max-height: 320px;
  }

  .note textarea{
    background: none;
    color: white;
    padding: 4px;
    max-height: 300px;
    height: 100%;
    resize: none;
  }

  select.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #212121; /* equivalent to bg-neutral-900 */
    color: #ffffff; /* text color for better contrast */
    padding: 0.5rem;
    border: 2px solid #ffffff; /* optional border */
    border-radius: 0.375rem; /* rounded corners */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%204%205%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M2%200L0%202h4zm0%205L0%203h4z%22/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 0.65rem auto;
  }

  select.custom-select::-ms-expand {
    display: none;
  }

  select.custom-select option {
    background-color: #212121;
    color: #ffffff;
  }

  @media (max-width: 450px) {
    .note {
      width: 100%;
      max-width: 100%;
      min-height: 460px;
      max-height: 460px;
    }
  }
</style>
