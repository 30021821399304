<script>
  export default {
    name: "Sidebar",
    components:{

    }
  }
</script>

<template>
  <div class="sidebar flex w-full h-full flex-col p-6 gap-4 text-white">
    <h1 class="text-2xl">Espace consommateurs</h1>
    <input type="search" name="search" id="search" placeholder="Nom complet" class="bg-neutral-700 outline-0 text-white p-4 rounded">
    <button class="flex p-4 w-full justify-center items-center bg-blue-300 text-white rounded hover:bg-blue-400">Ajouter</button>
    <hr class="flex w-full border-neutral-700">
    <div class="list flex w-full h-full gap-2 flex-col overflow-y-auto overflow-x-hidden">
      <button class="flex p-4 w-full bg-neutral-900 text-white rounded  hover:bg-neutral-700 gap-2 items-center">FNMM</button>
      <button class="flex p-4 w-full bg-neutral-900 text-white rounded  hover:bg-neutral-700 gap-2 items-center">K-POLE</button>
    </div>
  </div>
</template>

<style scoped>
  .sidebar{
    max-width: 390px;
    min-width: 390px;
  }
</style>