import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Clients from '../views/Clients.vue'
import Comptes from "@/views/Comptes.vue";
import Connexion from "@/views/Connexion.vue";

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/consommateurs',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/comptes',
    name: 'Comptes',
    component: Comptes
  },
  {
    path: '/espace-de-connexion',
    name: 'Connexion',
    component: Connexion
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
