<template>
  <section class="flex w-full p-6">
    <textarea class="interface w-full flex bg-black rounded flex-col text-white gap px-2" disabled v-model="logsText">
      <!-- Les logs seront affichés ici -->
    </textarea>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AppLogs',
  data() {
    return {
      logsText: '', // Initialiser la variable pour stocker les logs
      intervalId: null // Pour stocker l'identifiant de l'intervalle
    };
  },
  mounted() {
    this.fetchLogs(); // Appeler la fonction une première fois lors du montage du composant
    this.intervalId = setInterval(this.fetchLogs, 5000); // Appeler fetchLogs toutes les 5 secondes
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Nettoyer l'intervalle lorsque le composant est détruit
    }
  },
  methods: {
    async fetchLogs() {
      const token = localStorage.getItem('token'); // Récupérer le token depuis localStorage

      try {
        const response = await axios.post(
          'https://private-api.hbc-group.fr/logs/list',
          { token } // Envoyer le token dans le corps de la requête
        );

        // Récupérer les données de la réponse
        const data = response.data;

        // Formater les logs en texte
        const formattedLogs = data.logs.map(log => 
          `${new Date(log.date).toLocaleString()} ${log.user.prenom} ${log.user.nom} : ${log.description}`
        ).join('\n');

        this.logsText = formattedLogs; // Mettre à jour le texte de la zone de texte
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    }
  }
};
</script>

<style scoped>
textarea {
  min-height: 100px;
  max-height: 300px;
}
</style>
