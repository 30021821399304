<template>
  <section class="flex min-h-dvh w-full flex-col bg-neutral-800 items-center justify-center">
    <div class="form flex p-6 bg-neutral-900 rounded max-w-screen-lg flex-col w-full text-white gap-6">
      <h1 class="text-4xl flex items-center gap-2">
        <img src="@/assets/logo/logo-circle.png" class="flex w-14" />
        HBC PRIVATE
      </h1>
      <div class="flex flex-col gap-2 w-full">
        <label for="email">Email</label>
        <input v-model="email" type="email" name="email" id="email" placeholder="Email" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full" />
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label for="password">Mot de passe</label>
        <input v-model="password" type="password" name="password" id="password" placeholder="Mot de passe" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full" />
      </div>
      <button @click="login" class="flex p-4 w-full justify-center items-center bg-blue-300 text-white rounded hover:bg-blue-400">
        Se connecter
      </button>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Connexion',
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('https://private-api.hbc-group.fr/auth/login', {
          email: this.email,
          password: this.password
        });
        
        const { token } = response.data;
        localStorage.setItem('token', token);
        this.$router.push('/');
      } catch (error) {
        alert('Gros Nul bouuhhh.');
      }
    }
  }
};
</script>

<style scoped>
</style>
