<script>
  export default {
    name: 'Widget'
  }
</script>

<template>
  <section class="flex w-full gap-6 p-6 flex-wrap">
    <a href="/consommateurs" class="widget text-white gap-4 flex w-full bg-neutral-900 hover:bg-neutral-700 p-4 rounded flex-col">
      <span class="title flex items-center  gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-user-round"><path d="M18 20a6 6 0 0 0-12 0"/><circle cx="12" cy="10" r="4"/><circle cx="12" cy="12" r="10"/></svg>
        <h1 class="text-white">Consommateurs</h1>
      </span>
    </a>
    <a href="/" class="widget text-white gap-4 flex w-full bg-neutral-900 hover:bg-neutral-700 p-4 rounded flex-col">
      <span class="title flex items-center  gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-folder-open"><path d="m6 14 1.5-2.9A2 2 0 0 1 9.24 10H20a2 2 0 0 1 1.94 2.5l-1.54 6a2 2 0 0 1-1.95 1.5H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H18a2 2 0 0 1 2 2v2"/></svg>
        <h1 class="text-white">Documents</h1>
      </span>
    </a>
    <a href="/" class="widget text-white gap-4 flex w-full bg-neutral-900 hover:bg-neutral-700 p-4 rounded flex-col">
      <span class="title flex items-center  gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chart-no-axes-combined"><path d="M12 16v5"/><path d="M16 14v7"/><path d="M20 10v11"/><path d="m22 3-8.646 8.646a.5.5 0 0 1-.708 0L9.354 8.354a.5.5 0 0 0-.707 0L2 15"/><path d="M4 18v3"/><path d="M8 14v7"/></svg>
        <h1 class="text-white">Statistiques</h1>
      </span>
    </a>
    <a href="/comptes" class="widget text-white gap-4 flex w-full bg-neutral-900 hover:bg-neutral-700 p-4 rounded flex-col">
      <span class="title flex items-center  gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round-pen"><path d="M2 21a8 8 0 0 1 10.821-7.487"/><path d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z"/><circle cx="10" cy="8" r="5"/></svg>
        <h1 class="text-white">Comptes</h1>
      </span>
    </a>
<!--    <a href="/" class="widget text-white gap-4 flex w-full bg-neutral-900 hover:bg-neutral-700 p-4 rounded flex-col">-->
<!--      <span class="title flex items-center  gap-2">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-panel-top"><rect width="18" height="18" x="3" y="3" rx="2"/><path d="M3 9h18"/></svg>-->
<!--        <h1 class="text-white">Sites web</h1>-->
<!--      </span>-->
<!--    </a>-->
    <a href="/" class="widget text-white gap-4 flex w-full bg-neutral-900 hover:bg-neutral-700 p-4 rounded flex-col">
      <span class="title flex items-center  gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
        <h1 class="text-white">Communications</h1>
      </span>
    </a>
  </section>
</template>

<style scoped>
.widget {
  max-width: 289px;
  width: 100%;
}

@media (max-width: 750px) {
  .widget {
    max-width: 100%;
    width: 100%;
  }
}
</style>