<template>
  <AppHeader></AppHeader>
  <section class="flex min-h-dvh w-full flex-col bg-neutral-800 items-center pt-11">
    <AppLogs></AppLogs>
    <Servers></Servers>
    <Widgets></Widgets>
    <hr class="border-neutral-700 flex w-full max-w-screen-lg my-12">
    <h1 class="text-white w-full flex px-6 text-xl">Notes</h1>
    <Notes></Notes>
  </section>
</template>

<script>
import axios from 'axios';
import AppHeader from "@/components/AppHeader.vue";
import Widgets from "@/components/Index/Widgets.vue";
import Notes from "@/components/Index/Notes.vue";
import AppLogs from "@/components/AppLogs.vue";
import Servers from "@/components/Index/Servers.vue";

export default {
  name: 'Index',
  components: {
    AppHeader,
    Widgets,
    Notes,
    AppLogs,
    Servers
  },
  async mounted() {
    try {
      const token = localStorage.getItem('token');

      if (token) {
        const response = await axios.post('https://private-api.hbc-group.fr/check/token', { token });

        console.log('Response data:', response.data); // Pour débogage

        if (response.data.valid) {
          console.log('Token valide, accès autorisé.');
          // Ici, vous pouvez ajouter des actions si nécessaire pour un token valide
        } else {
          console.error('Token invalide, redirection vers la page de connexion.');
          localStorage.clear();
          this.$router.push('/espace-de-connexion');
        }
      } else {
        console.error('Aucun token trouvé, redirection vers la page de connexion.');
        this.$router.push('/espace-de-connexion');
      }
    } catch (error) {
      console.error('Erreur lors de la vérification du token:', error);
      localStorage.clear();
      this.$router.push('/espace-de-connexion');
    }
  }
}
</script>

<style scoped>
</style>
