<script>
  export default {
    name : 'Servers'
  }
</script>

<template>
  <div class="flex gap-6 p-6 w-full">
    <div class="state flex w-full items-center text-white p-4 justify-center rounded relative cursor-pointer border border-dotted hover:bg-neutral-700">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-plus"><circle cx="12" cy="12" r="10"/><path d="M8 12h8"/><path d="M12 8v8"/></svg>
    </div>

    <div class="state flex w-full items-center bg-neutral-700 text-white p-4 justify-between rounded relative cursor-pointer border border-dotted">
      <div class="flex items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-hard-drive"><line x1="22" x2="2" y1="12" y2="12"/><path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"/><line x1="6" x2="6.01" y1="16" y2="16"/><line x1="10" x2="10.01" y1="16" y2="16"/></svg>
        <span>hbc-group.fr</span>
      </div>
      <div class="">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle"><circle cx="12" cy="12" r="10"/></svg>
      </div>
      <div class="info absolute rounded bg-black w-full flex-col left-0 p-4">
        <span>Etat : Très bien</span>
        <span>Réponse : 3ms</span>
        <label for="hbc" class="flex w-full items-center gap-2"><input type="checkbox" name="hbc" id="hbc"> Mettre en maintenance</label>
      </div>
    </div>

    <div class="state flex w-full items-center bg-neutral-700 text-white p-4 justify-between rounded relative cursor-pointer border border-dotted">
      <div class="flex items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-hard-drive"><line x1="22" x2="2" y1="12" y2="12"/><path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"/><line x1="6" x2="6.01" y1="16" y2="16"/><line x1="10" x2="10.01" y1="16" y2="16"/></svg>
        <span>etudik.fr</span>
      </div>
      <div class="">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle"><circle cx="12" cy="12" r="10"/></svg>
      </div>
      <div class="info absolute rounded bg-black w-full flex-col left-0 p-4">
        <span>Etat : Très bien</span>
        <span>Réponse : 3ms</span>
        <label for="etudik" class="flex w-full items-center gap-2"><input type="checkbox" name="etudik" id="etudik"> Mettre en maintenance</label>
      </div>
    </div>

    <div class="state flex w-full items-center bg-neutral-700 text-white p-4 justify-between rounded relative cursor-pointer border border-dotted">
      <div class="flex items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-hard-drive"><line x1="22" x2="2" y1="12" y2="12"/><path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"/><line x1="6" x2="6.01" y1="16" y2="16"/><line x1="10" x2="10.01" y1="16" y2="16"/></svg>
        <span>menumatch.fr</span>
      </div>
      <div class="">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle"><circle cx="12" cy="12" r="10"/></svg>
      </div>
      <div class="info absolute rounded bg-black w-full flex-col left-0 p-4">
        <span>Etat : Très bien</span>
        <span>Réponse : 3ms</span>
        <label for="menumatch" class="flex w-full items-center gap-2"><input type="checkbox" name="menumatch" id="menumatch"> Mettre en maintenance</label>
      </div>
    </div>
  </div>
  <div class="addServeur w-full hidden flex-col items-center justify-center">
    <div class="flex w-full max-w-screen-lg items-center flex-col gap-2">
      <input type="text" name="title" id="title" placeholder="Titre" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      <input type="text" name="link" id="link" placeholder="URL" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      <button class="flex p-4 w-full justify-center items-center bg-blue-300 text-white rounded hover:bg-blue-400">Enregistrer</button>
    </div>
    <hr class="border-neutral-700 flex w-full max-w-screen-lg my-12">
  </div>

</template>

<style scoped>
  .state{
    max-width: 300px;
  }

  .info{
    display: none;
    top: 58px;
  }

  .state:hover .info{
    display: flex;
  }
</style>