<script>
  export default {
    name : 'Sidebar',
  }
</script>

<template>
  <div class="sidebar flex flex-col gap-4 w-full text-white p-6">
    <h1 class="text-2xl">Espace comptes</h1>
    <input type="search" name="search" id="search" placeholder="Rechercher un utilisateur" class="bg-neutral-700 outline-0 text-white p-4 rounded">
    <button class="flex p-4 w-full justify-center items-center bg-blue-300 text-white rounded hover:bg-blue-400">Ajouter</button>
    <hr class="flex w-full border-neutral-700">
    <div class="list flex w-full h-full gap-2 flex-col overflow-y-auto overflow-x-hidden">
      <button class="flex p-4 w-full bg-neutral-900 text-white rounded  hover:bg-neutral-700 gap-2 items-center">Hervé Duong</button>
      <button class="flex p-4 w-full bg-neutral-900 text-white rounded  hover:bg-neutral-700 gap-2 items-center">Bastien Rapaud</button>
    </div>
  </div>
</template>

<style scoped>
  .sidebar{
    max-width: 390px;
    min-width: 390px;
  }
</style>