<script>
  export default {
    name: 'AppHeader',
    components:{

    }
  }
</script>

<template>
  <nav class="flex w-full items-center justify-between px-6 py-2 border-b border-neutral-700 fixed bg-neutral-800 z-10">
    <a href="/">
      <img src="@/assets/logo/logo-circle.png" class="flex w-12">
    </a>
  </nav>
</template>

<style scoped>

</style>