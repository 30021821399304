<script>
  export default {
    name : 'Users'
  }
</script>

<template>
  <div class="flex w-full flex-col p-6 gap-6 max-w-screen-lg text-white">
    <h1 class="text-2xl">Informations</h1>

    <div class="flex items-center gap-6">
      <div class="flex flex-col gap-2 w-full">
        <label for="lastname">Nom</label>
        <input type="text" name="lastname" id="lastname" placeholder="Nom" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label for="firstname">Prénom</label>
        <input type="text" name="firstname" id="firstname" placeholder="Prénom" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label for="phone">Téléphone</label>
        <input type="text" name="phone" id="phone" placeholder="Téléphone" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
    </div>
    <div class="flex flex-col gap-2 w-full">
      <label for="email">Email</label>
      <input type="email" name="email" id="email" placeholder="Email" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
    </div>
    <div class="flex items-center gap-6">
      <div class="flex flex-col gap-2 w-full">
        <label for="adress">Adresse</label>
        <input type="text" name="adress" id="adress" placeholder="Adresse" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label for="postal">Postale</label>
        <input type="text" name="postal" id="postal" placeholder="Postale" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label for="city">Ville</label>
        <input type="text" name="city" id="city" placeholder="Ville" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
    </div>
    <hr class="flex w-full border-neutral-700">
    <div class="flex items-center gap-6">
      <div class="flex flex-col gap-2 w-full">
        <label for="password">Mot de passe</label>
        <input type="password" name="password" id="password" placeholder="Mot de passe" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label for="confirm">Confirmation</label>
        <input type="password" name="confirm" id="confirm" placeholder="Confirmation" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
    </div>
    <div class="accpet flex items-center gap-2">
      <input type="checkbox" name="accept" id="accept">
      <label for="accept">J'approuve que les informations renseignées soient exactes.</label>
    </div>
    <button class="flex p-4 w-full justify-center items-center bg-blue-300 text-white rounded hover:bg-blue-400">Enregistrer</button>
    <button class="flex p-4 w-full justify-center items-center bg-red-500 text-white rounded hover:bg-red-600">Supprimer</button>
  </div>
</template>

<style scoped>

</style>