<script>
  export default {
    name: 'Infos',
    components:{

    }
  }
</script>

<template>
  <section class="flex h-full w-full min-w-screen p-6">
    <div class="flex flex-col w-full gap-6 max-w-screen-lg min-w-screen-lg text-white">
      <h1 class="text-2xl">Informations</h1>
      <span>Représentant</span>
      <div class="flex gap-6 items-center w-full">
        <input type="text" name="lastname" id="lastname" placeholder="Nom" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
        <input type="text" name="firstname" id="firstname" placeholder="Prénom" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
        <input type="text" name="role" id="role" placeholder="Rôle" value="" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
      <div class="flex gap-6 items-center w-full">
        <input type="email" name="mailPerson" id="mailPerson" value="" placeholder="Email" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
        <input type="text" name="phonePerson" id="phonePerson" value="" placeholder="Téléphone" class="flex bg-neutral-700 outline-0 text-white p-4 rounded w-full">
      </div>
      <button class="flex p-4 w-full justify-center items-center bg-blue-300 text-white rounded hover:bg-blue-400">Ajouter un autre représentant</button>
      <hr class="border-neutral-700 w-full flex">
      <div class="flex flex-col gap-2">
        <label for="compagny">Noms commerciaux</label>
        <input type="text" name="compagny" id="compagny" value="" placeholder="Nom de l'entreprise" class="bg-neutral-700 outline-0 text-white p-4 rounded flex w-full">
      </div>
      <div class="flex gap-6 items-center w-full">
        <div class="flex flex-col gap-2 w-full">
          <label for="phone">Téléphone</label>
          <input type="text" name="phone" id="phone" value="" placeholder="Téléphone" class="bg-neutral-700 outline-0 text-white p-4 rounded flex w-full">
        </div>
        <div class="flex flex-col gap-2 w-full">
          <label for="phone">Email</label>
          <input type="email" name="email" id="email" value="" placeholder="Email" class="bg-neutral-700 outline-0 text-white p-4 rounded flex w-full">
        </div>
        <div class="flex flex-col gap-2 w-full">
          <label for="adress">Adresse postale</label>
          <input type="text" name="adress" id="adress" value="" placeholder="Adresse postale" class="bg-neutral-700 outline-0 text-white p-4 rounded flex w-full">
        </div>
      </div>
      <div class="flex gap-6 items-center w-full">
        <div class="flex flex-col gap-2 w-full">
          <label for="siren">Numéro SIREN</label>
          <input type="text" name="siren" id="siren" placeholder="Siren" value="" class="bg-neutral-700 outline-0 text-white p-4 rounded flex w-full">
        </div>

        <div class="flex flex-col gap-2 w-full">
          <label for="adress">Numéro SIRET</label>
          <input type="text" name="siret" id="siret" placeholder="Siret" value="" class="bg-neutral-700 outline-0 text-white p-4 rounded flex w-full">
        </div>
      </div>
      <button class="flex p-4 w-full justify-center items-center bg-blue-300 text-white rounded hover:bg-blue-400">Enregistrer</button>
      <button class="flex p-4 w-full justify-center items-center bg-red-500 text-white rounded hover:bg-red-600">Supprimer</button>
    </div>
  </section>
</template>

<style scoped>

</style>