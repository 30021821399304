<script>
  export default {
    name:'Documents',
    components:{

    }
  }
</script>

<template>
  <div class="docs flex w-full h-full p-6 flex-col gap-6 text-white">
    <h1 class="text-2xl">Documents</h1>
    <input type="search" name="search" id="search" placeholder="Documents" class="bg-neutral-700 outline-0 text-white p-4 rounded">
    <label for="upload" class="flex p-4 w-full justify-center items-center bg-blue-300 text-white rounded hover:bg-blue-400 cursor-pointer">Upload PDF</label>
    <input type="file" name="upload" id="upload" class="hidden">
    <hr class="flex w-full border-neutral-700">
    <div class="list flex flex-col gap-2 max-h-full overflow-y-auto overflow-x-hidden">
      <div class="doc text-white flex border p-4 justify-between items-center rounded cursor-pointer">
        <span class="overflow-ellipsis">Devis_Site_Web.pdf</span>
        <div class="flex gap-2 hover:text-red-500">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .docs{
    max-width: 390px;
  }

  .doc span {
    max-width: 260px; /* Ajustez cette valeur selon vos besoins */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block; /* Assurez-vous que l'élément span est de type inline-block */
  }
</style>