<script>
  import AppHeader from "@/components/AppHeader.vue";
  import Users from "@/components/Comptes/Users.vue";
  import Sidebar from "@/components/Comptes/Sidebar.vue";

  export default {
    name : 'Comptes',
    components : {
      AppHeader,
      Users,
      Sidebar
    }
  }
</script>

<template>
  <AppHeader></AppHeader>
  <section class="flex min-h-dvh w-full flex-col bg-neutral-800 items-center">
    <section class="flex w-full h-dvh pt-20">
      <Sidebar></Sidebar>
      <Users></Users>
    </section>
  </section>
</template>

<style scoped>

</style>