<script>
  import AppHeader from "@/components/AppHeader.vue";
  import Sidebar from "@/components/Clients/Sidebar.vue";
  import Infos from "@/components/Clients/Infos.vue";
  import Documents from "@/components/Clients/Documents.vue";

  export default {
    name : 'Clients',
    components:{
      AppHeader,
      Sidebar,
      Infos,
      Documents
    }
  }
</script>

<template>
  <AppHeader></AppHeader>
  <section class="flex min-h-dvh w-full flex-col bg-neutral-800 items-center">
    <section class="flex w-full h-dvh pt-20">
      <Sidebar></Sidebar>
      <Infos></Infos>
      <Documents></Documents>
    </section>
  </section>
</template>

<style scoped>

</style>